import React from 'react'
import { useTheme } from 'styled-components'

const HandoffRules = () => {
  const theme = useTheme()
  return (
    <div>
      <h2 style={{ marginTop: 10 }}>Handoff rules</h2>
      <p style={{ color: theme.colors.text250, marginBottom: 15 }}>
        Customize your Super AI with knowledge, instructions on how to handoff
        tasks, how it sounds, and what it is and is not allowed to answer.
      </p>
    </div>
  )
}

export default HandoffRules
