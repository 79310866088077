import React from 'react'
import { useRecoilValue } from 'recoil'
import { windowDimensionsAtom } from '../../../AppRoutes'

export default function FixedBottomButtons({
  children,
}: {
  children: React.ReactNode
}) {
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: windowDimensions.isMobile ? 0 : 250,
        right: 0,
        background: 'white',
        borderTop: '1px solid #E5E7EB',
        padding: '16px 24px',
        zIndex: 100,
      }}
    >
      {children}
    </div>
  )
}
