import React, { useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  allKnowledgeBasesAtom,
  authenticatedUserAtom,
  companyAssociationsAtom,
  knowledgeBaseFormAtom,
} from 'state/atoms'
import {
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  SortableTableHeaderCell,
} from 'components/lib/NewTable'
import { FlexRow, IconButton, TableCell, TextButton } from 'components/lib'
import Search from 'components/lib/Search'
import { orderBy } from 'lodash'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import { MoreHoriz, AutoStoriesOutlined, Add } from '@mui/icons-material'
import { deleteKnowledgeBase } from 'api/assistant/knowledgeBase'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { Divider } from '@mui/material'

enum SortOption {
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Association = 'association',
}

const KnowledgeBaseTable = () => {
  const companyAssociations = useRecoilValue(companyAssociationsAtom)
  const [knowledgeBases, setKnowledgeBases] = useRecoilState(
    allKnowledgeBasesAtom,
  )
  const [, setKnowledgeBaseForm] = useRecoilState(knowledgeBaseFormAtom)
  const [searchText, setSearchText] = useState('')
  const [sortKey, setSortKey] = useState(SortOption.UpdatedAt)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const { selectedContact } = useRecoilValue(authenticatedUserAtom)

  const handleSort = (nextSort: SortOption) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const filteredKnowledgeBases = useMemo(
    () =>
      (knowledgeBases || []).filter(
        kb =>
          kb.title.toLowerCase().includes(searchText.toLowerCase()) ||
          kb.prompt.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [searchText, knowledgeBases],
  )

  const sortedKnowledgeBases = useMemo(() => {
    if (sortKey === SortOption.UpdatedAt) {
      return orderBy(
        filteredKnowledgeBases,
        kb => (kb.updatedAt ? kb.updatedAt : kb.createdAt),
        sortOrder,
      )
    }

    if (sortKey === SortOption.Title) {
      return orderBy(filteredKnowledgeBases, 'title', sortOrder)
    }

    if (sortKey === SortOption.Association) {
      return orderBy(
        filteredKnowledgeBases,
        kb => kb.associationIds.join(','),
        sortOrder,
      )
    }

    return filteredKnowledgeBases
  }, [sortKey, sortOrder, filteredKnowledgeBases])

  return (
    <div style={{ marginTop: -40 }}>
      <FlexRow align="center" justify="flex-end">
        <FlexRow>
          <TextButton
            onClick={() => {
              setKnowledgeBaseForm({
                knowledgeBase: {
                  title: '',
                  associationIds: selectedContact?.associationIds || [],
                  prompt: '',
                },
                isOpen: true,
              })
            }}
          >
            <Add style={{ fontSize: 18 }} />
            <h4 style={{ fontWeight: 500 }}>Add</h4>
          </TextButton>
        </FlexRow>
        <Search
          text={searchText}
          changeText={setSearchText}
          placeholder="Search knowledge bases"
        />
      </FlexRow>

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow style={{ width: '100%' }}>
              <SortableTableHeaderCell
                active={sortKey === SortOption.Title}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Title)}
                style={{
                  textAlign: 'left',
                  width: '70%',
                  border: 'none',
                }}
              >
                Title
              </SortableTableHeaderCell>

              <SortableTableHeaderCell
                active={sortKey === SortOption.Association}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Association)}
                style={{
                  textAlign: 'left',
                  width: '20%',
                  border: 'none',
                  outline: 'none',
                }}
              >
                Accounts
              </SortableTableHeaderCell>
              <TableHeaderCell
                style={{
                  textAlign: 'left',
                  width: '5%',
                  border: 'none',
                  outline: 'none',
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedKnowledgeBases && sortedKnowledgeBases.length > 0 ? (
              sortedKnowledgeBases.map(kb => (
                <TableRow key={kb.id}>
                  <TableCell
                    style={{
                      textTransform: 'none',
                      borderRight: '1px solid #E1E4E8',
                      paddingLeft: 10,
                    }}
                  >
                    <AutoStoriesOutlined
                      style={{ marginRight: 12, fontSize: 18 }}
                    />
                    {kb.title}
                  </TableCell>

                  <TableCell
                    style={{
                      textTransform: 'none',
                      borderRight: '1px solid #E1E4E8',
                      paddingLeft: 10,
                    }}
                  >
                    {kb.associationIds.length === 1
                      ? companyAssociations.associations.find(
                          a => a.id === kb.associationIds[0],
                        )?.name
                      : `${kb.associationIds.length} properties`}
                  </TableCell>
                  <TableCell style={{ textTransform: 'none' }}>
                    <StyledSelect
                      renderValue={() => (
                        <IconButton>
                          <MoreHoriz />
                        </IconButton>
                      )}
                      defaultValue=""
                    >
                      <StyledMenuItem
                        onClick={() => {
                          setKnowledgeBaseForm({
                            knowledgeBase: kb,
                            isOpen: true,
                          })
                        }}
                      >
                        Edit
                      </StyledMenuItem>

                      <StyledMenuItem
                        onClick={() => {
                          setKnowledgeBaseForm({
                            knowledgeBase: {
                              title: kb.title,
                              associationIds: kb.associationIds,
                              prompt: kb.prompt,
                            },
                            isOpen: true,
                          })
                        }}
                      >
                        Duplicate
                      </StyledMenuItem>
                      <Divider />
                      <StyledMenuItem
                        style={{ color: '#d62323' }}
                        onClick={() => {
                          deleteKnowledgeBase(kb.id, selectedContact.companyId)
                            .then(() => {
                              toastSuccess('Knowledge base deleted')
                              setKnowledgeBases(prev =>
                                prev.filter(k => k.id !== kb.id),
                              )
                            })
                            .catch(() => {
                              toastError('Failed to delete knowledge base')
                            })
                        }}
                      >
                        Delete
                      </StyledMenuItem>
                    </StyledSelect>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{
                    textTransform: 'none',
                    textAlign: 'left',
                    fontWeight: 500,
                    color: '#627088',
                  }}
                >
                  No knowledge bases to display.{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      setKnowledgeBaseForm({
                        knowledgeBase: {
                          title: '',
                          associationIds: selectedContact?.associationIds || [],
                          prompt: '',
                        },
                        isOpen: true,
                      })
                    }}
                  >
                    Add a knowledge base
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  )
}

export default KnowledgeBaseTable
