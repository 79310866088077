import {
  APIAssistantConfiguration,
  APIKnowledgeBase,
  AssistantConfiguration,
  KnowledgeBase,
} from '@super-software-inc/foundation'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'

export const getAssistantConfiguration = async (
  companyId: string,
): Promise<AssistantConfiguration> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const getAssistantConfigurationAPI: HttpsCallable<
    { companyId: string },
    AssistantConfiguration
  > = httpsCallable(functions, 'getAssistantConfiguration')

  const config = await getAssistantConfigurationAPI({ companyId })

  return config.data
}

export const updateAssistantConfiguration = async (
  companyId: string,
  assistantConfiguration: Partial<APIAssistantConfiguration>,
): Promise<Partial<APIAssistantConfiguration>> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const updateAssistantConfigurationAPI: HttpsCallable<
    {
      companyId: string
      assistantConfiguration: APIAssistantConfiguration
    },
    APIAssistantConfiguration
  > = httpsCallable(functions, 'updateAssistantConfiguration')

  const config = await updateAssistantConfigurationAPI({
    companyId,
    assistantConfiguration: assistantConfiguration as APIAssistantConfiguration,
  })

  const data = {
    ...config.data,
    id: companyId,
  }

  return data
}
export const getKnowledgeBases = async (
  companyId: string,
): Promise<APIKnowledgeBase[]> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const getKnowledgeBasesAPI: HttpsCallable<
    { companyId: string },
    APIKnowledgeBase[]
  > = httpsCallable(functions, 'getKnowledgeBases')

  const knowledgeBases = await getKnowledgeBasesAPI({ companyId })
  const apiKnowledgeBases = knowledgeBases.data.map(kb => ({
    ...kb,
    id: kb.id,
  }))

  return apiKnowledgeBases
}

export const getKnowledgeBaseById = async (
  knowledgeBaseId: string,
  companyId: string,
): Promise<KnowledgeBase> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const getKnowledgeBaseByIdAPI: HttpsCallable<
    { knowledgeBaseId: string; companyId: string },
    KnowledgeBase
  > = httpsCallable(functions, 'getKnowledgeBaseById')

  const knowledgeBase = await getKnowledgeBaseByIdAPI({
    knowledgeBaseId,
    companyId,
  })

  return knowledgeBase.data
}

export const createKnowledgeBase = async (
  companyId: string,
  knowledgeBase: Partial<KnowledgeBase>,
): Promise<APIKnowledgeBase> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const createKnowledgeBaseAPI: HttpsCallable<
    { companyId: string; knowledgeBase: Partial<KnowledgeBase> },
    APIKnowledgeBase
  > = httpsCallable(functions, 'createKnowledgeBase')

  const kb = await createKnowledgeBaseAPI({
    companyId,
    knowledgeBase,
  })

  const data = {
    ...kb.data,
    id: kb.data.id,
  }
  return data
}

export const updateKnowledgeBase = async (
  companyId: string,
  knowledgeBaseId: string,
  knowledgeBase: Partial<APIKnowledgeBase>,
): Promise<APIKnowledgeBase> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const updateKnowledgeBaseAPI: HttpsCallable<
    {
      companyId: string
      knowledgeBaseId: string
      knowledgeBase: Partial<KnowledgeBase>
    },
    APIKnowledgeBase
  > = httpsCallable(functions, 'updateKnowledgeBase')

  const kb = await updateKnowledgeBaseAPI({
    companyId,
    knowledgeBaseId,
    knowledgeBase,
  })

  return kb.data
}

export const deleteKnowledgeBase = async (
  knowledgeBaseId: string,
  companyId: string,
): Promise<void> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const deleteKnowledgeBaseAPI: HttpsCallable<
    { knowledgeBaseId: string; companyId: string },
    void
  > = httpsCallable(functions, 'deleteKnowledgeBase')

  await deleteKnowledgeBaseAPI({ knowledgeBaseId, companyId })
}
