import React from 'react'
import styled from 'styled-components/macro'

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-wrap: wrap;
  align-items: center;
`

const RadioButton = ({
  value,
  comparator,
  label,
  name,
  ...rest
}: {
  value: string | number
  comparator: string | number | undefined
  label: string
  name?: string
}) => (
  <RadioLabel>
    <input
      type="radio"
      name={name}
      value={value}
      checked={value === comparator}
      style={{ margin: '0 11px 0 0', cursor: 'pointer' }}
      readOnly
      {...rest}
    />
    {label}
  </RadioLabel>
)

export default RadioButton
