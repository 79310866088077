import {
  Association,
  PropertyInfo,
  PropertyRelation,
} from '@super-software-inc/foundation'
import {
  DropdownTriggerButton,
  FlexRow,
  FormDropdown,
  MultilevelDropdown,
  MultilevelDropdownPositions,
  MultilevelHeader,
  MultilevelItem,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import { MultilevelNoResults } from 'components/lib/MultilevelDropdown'
import React, { useEffect, useMemo, useState } from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import {
  associationChoicesAtom,
  windowDimensionsAtom,
} from '../../../AppRoutes'

const PropertySelector = ({
  value,
  onChange,
  isDisabled = false,
  allowMultiSelect = true,
  loading = false,
  isTaskSheet = true,
  includeCompany = true,
  width = '215px',
}: {
  value: (string | null)[]
  onChange: Function
  isDisabled?: boolean
  allowMultiSelect?: boolean
  loading?: boolean
  isTaskSheet?: boolean
  includeCompany?: boolean
  width?: string
}) => {
  const [filteredAssociations, setFilteredAssociations] = useState<any[]>([])
  const [associationSearchValue, setAssociationSearchValue] = useState('')
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  useEffect(() => {
    setFilteredAssociations(
      associationChoices.filter((hoa: Association) =>
        hoa.name
          .toLowerCase()
          .includes(associationSearchValue.trim().toLowerCase()),
      ),
    )
  }, [associationSearchValue, associationChoices])

  const showCompanyChoice = useMemo(() => {
    // check if the user is a staff or management of any property
    if (
      authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) =>
          p.propertyRelation &&
          [PropertyRelation.Staff, PropertyRelation.PropertyManager].includes(
            p.propertyRelation,
          ),
      ) !== undefined
    ) {
      return true
    }
    // If not, check if no prop is already selected for this task
    if (value.length === 1 && value[0] === null) {
      return true
    }
    return false
  }, [value, authenticatedUser.selectedContact.propertyInfo])

  return (
    <MultilevelDropdown
      loading={loading}
      isDisabled={isDisabled}
      position={MultilevelDropdownPositions.Right}
      maxHeight="260px"
      trigger={
        isTaskSheet ? (
          <DropdownTriggerButton
            type="button"
            hasValue={value.length > 0}
            style={{
              marginRight: 5,
              marginBottom: windowDimensions.isMobile ? 5 : 'unset',
              maxWidth: 120,
            }}
          />
        ) : undefined
      }
      title={
        isTaskSheet ? (
          <>
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, paddingRight: 6 }}
            >
              location_on
            </span>
            {value.length > 1 ? (
              `${
                associationChoices?.length === value.length
                  ? 'All'
                  : value.length
              } properties`
            ) : (
              <Tooltip
                placement="bottom"
                overlay={
                  <span>
                    {!associationChoices || value.length < 1
                      ? 'Property'
                      : value[0] === null
                      ? authenticatedUser.selectedCompany.name
                      : associationChoices.filter(a => a.id === value[0])[0]
                          ?.name}
                  </span>
                }
              >
                <TruncatedText>
                  {!associationChoices || value.length < 1
                    ? 'Property'
                    : value[0] === null
                    ? authenticatedUser.selectedCompany.name
                    : associationChoices.filter(a => a.id === value[0])[0]
                        ?.name}
                </TruncatedText>
              </Tooltip>
            )}
          </>
        ) : (
          <FormDropdown width={width}>
            <FlexRow align="center" justify="space-between">
              <TruncatedText>
                <span style={{ fontWeight: 400 }}>
                  {value.length > 1
                    ? `${
                        associationChoices?.length === value.length
                          ? 'All'
                          : value.length
                      } properties`
                    : associationChoices.filter(a => a.id === value[0])[0]
                        ?.name}
                </span>
              </TruncatedText>
              <span
                className="material-symbols-rounded"
                style={{ fontSize: 20, color: '#8A94A6', marginTop: 3 }}
              >
                keyboard_arrow_down
              </span>
            </FlexRow>
          </FormDropdown>
        )
      }
      closeOnClick={false}
    >
      <MultilevelHeader
        onChange={setAssociationSearchValue}
        isDisabled={isDisabled}
        value={associationSearchValue}
        placeholder="Search properties"
        clearValue={() => setAssociationSearchValue('')}
      />
      {associationChoices.length > 1 &&
        allowMultiSelect &&
        filteredAssociations.length === associationChoices.length && (
          <MultilevelItem
            key="all-associations"
            onClick={() => {
              if (value.length > 0) {
                onChange([])
              } else {
                onChange(associationChoices.map(a => a.id))
              }
            }}
          >
            <FlexRow align="center">
              {value.length === 0
                ? `Select All (${associationChoices.length})`
                : `Deselect All (${value.length})`}
            </FlexRow>
          </MultilevelItem>
        )}
      {includeCompany && showCompanyChoice && (
        <>
          <MultilevelItem
            key={null}
            onClick={() => {
              const isDeselect = value.indexOf(null) !== -1
              if (isDeselect) {
                onChange(value.filter(a => a !== null))
              } else {
                onChange([...value, null])
              }
            }}
          >
            <FlexRow>
              {value.indexOf(null) !== -1 ? (
                <MdCheckBox style={{ fontSize: 18, marginRight: 5 }} />
              ) : (
                <MdCheckBoxOutlineBlank
                  style={{
                    fontSize: 18,
                    color: '#C9CED6',
                    marginRight: 5,
                  }}
                />
              )}
              {authenticatedUser.selectedCompany.name}
            </FlexRow>
          </MultilevelItem>
          <hr className="h-px my-1 mx-3 bg-gray-200 border-0" />
        </>
      )}
      {filteredAssociations.map(association => (
        <MultilevelItem
          key={`${association.id}+${association.name}`}
          onClick={() => {
            const isDeselect = value.indexOf(association.id) !== -1
            if (isDeselect) {
              onChange(value.filter(a => a !== association.id))
            } else {
              onChange([...value, association.id])
            }
          }}
        >
          <FlexRow>
            {value.indexOf(association.id) !== -1 ? (
              <MdCheckBox style={{ fontSize: 18, marginRight: 5 }} />
            ) : (
              <MdCheckBoxOutlineBlank
                style={{
                  fontSize: 18,
                  color: '#C9CED6',
                  marginRight: 5,
                }}
              />
            )}
            <TruncatedText>{association.name}</TruncatedText>
          </FlexRow>
        </MultilevelItem>
      ))}
      {filteredAssociations.length < 1 && <MultilevelNoResults />}
    </MultilevelDropdown>
  )
}
export default PropertySelector
