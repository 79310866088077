import { FlexRow, PageTitle, PrimaryButton } from 'components/lib'
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { useTheme } from 'styled-components'
import formatPhoneNumber from 'utils/formatPhoneNumber'

// integration images
import appfolio from 'assets/integrationsImages/appfolio.png'
import applecalendar from 'assets/integrationsImages/applecalendar.png'
import applemail from 'assets/integrationsImages/applemail.png'
import buildium from 'assets/integrationsImages/buildium.png'
import dialpad from 'assets/integrationsImages/dialpad.png'
import gmail from 'assets/integrationsImages/gmail.png'
import googlecalendar from 'assets/integrationsImages/googlecalendar.png'
import jackjaffa from 'assets/integrationsImages/jackjaffa.png'
import more from 'assets/integrationsImages/more.png'
import outlook from 'assets/integrationsImages/outlook.png'
import phone from 'assets/integrationsImages/phone.png'
import propertymeld from 'assets/integrationsImages/propertymeld.png'
import rentmanager from 'assets/integrationsImages/rentmanager.png'
import ringcentral from 'assets/integrationsImages/ringcentral.png'
import { updateCompany } from 'api/companies'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { Timezone } from '@super-software-inc/foundation/dist/types/Company'
import TimezoneSelector from 'components/app/Dropdowns/TimezoneSelector'
import CalendarSyncModal from 'components/app/CalendarSyncModal'
import NiceModal from '@ebay/nice-modal-react'
import { CalendarMonth } from '@mui/icons-material'
import { associationChoicesAtom } from '../../../AppRoutes'

interface Integrations {
  image: string
  title: string
  link: string
}

const emailIntegrations: Integrations[] = [
  {
    image: gmail,
    title: 'Gmail',
    link: 'https://www.notion.so/hiresuper/Google-Mail-Email-Forwarding-252d4d4054d3404bb50216cacf12d04e',
  },
  {
    image: outlook,
    title: 'Microsoft Outlook 365',
    link: 'https://www.notion.so/hiresuper/Outlook-Mail-Email-Forwarding-ad27fdaedc8c4523beaa5a37164cb405',
  },
  {
    image: applemail,
    title: 'Apple Mail',
    link: 'https://www.notion.so/hiresuper/Apple-Mail-email-forwarding-8a81fee27e5543b58df23f19154fbc2b',
  },
]

const phoneIntegrations: Integrations[] = [
  {
    image: phone,
    title: 'Add Super to your IVR',
    link: 'https://www.notion.so/hiresuper/Add-Super-to-your-IVR-a878d0f7a07c43cca260604aee34b0c9',
  },
  {
    image: ringcentral,
    title: 'Forward RingCentral messages to Super',
    link: 'https://www.notion.so/hiresuper/Forward-RingCentral-messages-948af52007364cc9964151afacbc8516',
  },
  {
    image: dialpad,
    title: 'Forward Dialpad messages to Super',
    link: 'https://www.notion.so/hiresuper/Forward-Dialpad-messages-59653bc373454d349f8accff39f0278e',
  },
]

const calendarIntegrations: Integrations[] = [
  {
    image: googlecalendar,
    title: 'Google Calendar',
    link: 'https://www.notion.so/hiresuper/Syncing-your-Super-calendar-with-other-calendar-apps-2109b9efce4a4d648e82ff6d32871ce1?pvs=4#19ba47d2672d47a18c42488533bc72cb',
  },
  {
    image: outlook,
    title: 'Outlook Calendar',
    link: 'https://www.notion.so/hiresuper/Syncing-your-Super-calendar-with-other-calendar-apps-2109b9efce4a4d648e82ff6d32871ce1?pvs=4#d30d874fd461410f92fa647baeca5c31',
  },
  {
    image: applecalendar,
    title: 'Apple Calendar',
    link: 'https://www.notion.so/hiresuper/Syncing-your-Super-calendar-with-other-calendar-apps-2109b9efce4a4d648e82ff6d32871ce1?pvs=4#468500d3c3874187acae31faf724de3f',
  },
]

const propertyManagementIntegrations: Integrations[] = [
  {
    image: rentmanager,
    title: 'Rent Manager',
    link: 'https://www.notion.so/hiresuper/Sync-notifications-from-other-property-management-systems-fa2721873dc74fc3a286a51afffecd72#616ca3a1e7fa4ff48b979d859038866e',
  },
  {
    image: appfolio,
    title: 'AppFolio',
    link: 'https://www.notion.so/hiresuper/Sync-notifications-from-other-property-management-systems-fa2721873dc74fc3a286a51afffecd72?pvs=4#b32d685c15e544d7bf8082ff89a58100',
  },
  {
    image: buildium,
    title: 'Buildium',
    link: 'https://www.notion.so/hiresuper/Sync-notifications-from-other-property-management-systems-fa2721873dc74fc3a286a51afffecd72?pvs=4#2998a99a7c13483dbbc62d0fe6a0f40c',
  },
  {
    image: propertymeld,
    title: 'Property Meld',
    link: 'https://www.notion.so/hiresuper/Sync-notifications-from-other-property-management-systems-fa2721873dc74fc3a286a51afffecd72?pvs=4#d67ed2a64d8b4071a31bc4d39379e5ea',
  },
  {
    image: jackjaffa,
    title: 'Jack Jaffa',
    link: 'https://www.notion.so/hiresuper/Sync-notifications-from-other-property-management-systems-fa2721873dc74fc3a286a51afffecd72?pvs=4#f80b2a120d31452d8e7d7ab98f9b9139',
  },
  {
    image: more,
    title: 'Looking for another integration?',
    link: 'mailto: sales@hiresuper.com?subject=Looking for another integration',
  },
]

const IntegrationSection = ({
  title,
  description,
  integrations,
}: {
  title: string
  description: string
  integrations: Integrations[]
}) => {
  const theme = useTheme()
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const { selectedContact } = useRecoilValue(authenticatedUserAtom)

  return (
    <div style={{ marginTop: 28 }}>
      <PageTitle>{title}</PageTitle>
      <p style={{ color: theme.colors.text250, paddingBottom: 12 }}>
        {description}
      </p>
      {title === 'Calendar' && (
        <PrimaryButton
          onClick={() =>
            NiceModal.show(CalendarSyncModal, {
              hoaNames: associationChoices.map(a => a.name),
              user: selectedContact,
            }).then(() => {})
          }
          style={{ marginBottom: 20 }}
        >
          <CalendarMonth style={{ fontSize: 12, marginRight: 5 }} />
          Personal link for calendar sync
        </PrimaryButton>
      )}

      <FlexRow style={{ flexWrap: 'wrap', gap: 24 }}>
        {integrations.map(integration => (
          <a
            href={integration.link}
            target="_blank"
            rel="noreferrer"
            key={integration.title}
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingRight: 16,
              paddingLeft: 16,
              paddingTop: 32,
              paddingBottom: 32,
              border: `solid 1px ${theme.colors.border}`,
              borderRadius: 8,
              width: 250,
              height: 208,
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12)',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              <img
                src={integration.image}
                style={{ width: 88, height: 88 }}
                alt={integration.title}
              />
              <p
                style={{
                  textAlign: 'center',
                  color: theme.colors.text100,
                  fontWeight: 500,
                  marginTop: 16,
                }}
              >
                {integration.title}
              </p>
            </div>
          </a>
        ))}
      </FlexRow>
    </div>
  )
}

const IntegrationsPage = () => {
  const emailDomain =
    process.env.REACT_APP_SENDGRID_APP_DOMAIN || 'staging.mail.hiresuper.com'

  const theme = useTheme()
  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserAtom,
  )

  const setSelectedTimezone = (timezone: Timezone) => {
    updateCompany({
      ...authenticatedUser.selectedCompany,
      timezone,
    })
      .then(company => {
        toastSuccess(`Company timezone updated to ${timezone}`)
        setAuthenticatedUser({
          ...authenticatedUser,
          selectedCompany: {
            ...authenticatedUser.selectedCompany,
            timezone,
          },
        })
      })
      .catch(error => {
        toastError('Failed to update company timezone')
      })
  }

  return (
    <div style={{ padding: 20, paddingTop: 0, marginLeft: 20 }}>
      <PageTitle>Company contact information</PageTitle>
      <p style={{ color: theme.colors.text250 }}>
        {authenticatedUser.selectedCompany.slug}@{emailDomain}
      </p>
      {authenticatedUser.selectedCompany.phone && (
        <p style={{ color: theme.colors.text250 }}>
          {formatPhoneNumber(authenticatedUser.selectedCompany.phone)}
        </p>
      )}
      <FlexRow align="center">
        <p style={{ marginRight: 5 }}>Company timezone: </p>
        <TimezoneSelector
          selectedValue={authenticatedUser.selectedCompany.timezone}
          setSelectedValue={setSelectedTimezone}
        />
      </FlexRow>
      <div style={{ marginTop: 30 }}>
        <IntegrationSection
          title="Email"
          description="Connect your inboxes. Select your email service provider."
          integrations={emailIntegrations}
        />
        <IntegrationSection
          title="Phone"
          description="Set up your Super phone line, or hook in existing phone lines."
          integrations={phoneIntegrations}
        />
        <IntegrationSection
          title="Calendar"
          description="Sync tasks with due dates to your work calendar."
          integrations={calendarIntegrations}
        />
        <IntegrationSection
          title="Property Management System"
          description="Sync notifications from your property management system into Super."
          integrations={propertyManagementIntegrations}
        />
      </div>
    </div>
  )
}

export default IntegrationsPage
